import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = theme => ({});

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

/**
 * In progress circular
 * prop progress for progress bar (0-100)
 */
class InProgress extends Component {
    renderProgressBar( progress ) {
        if( null != progress ) {
            if( progress !== 0 && progress !== 100 ) {
                return (
                    <BorderLinearProgress variant="determinate" value={progress} />
                );
            }
        }
    }

    render() {
        let progressBarContent = this.renderProgressBar( this.props.progress )

        return (
            <div align="center" style={{width:"100%"}}>
                <Box display="flex" m={3} alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
                <div style={{ width:"33%"}}>
                    {progressBarContent}
                </div>
            </div>
        );
    }
};

export default withStyles(useStyles)(InProgress);