import React, { Component } from 'react';
import ProjectService from '../classes/ProjectService.js';
import ReportService from '../classes/ReportService.js';
import UsersToTasksService from '../classes/UsersToTasksService.js';
import UsersToProjectsService from '../classes/UsersToProjectsService.js';
import UserService from '../classes/UserService.js';
import UserTypeService from '../classes/UserTypeService.js';
import TaskService from '../classes/TaskService.js';
import AuthContext from '../contexts/AuthContext.js';
import SelectControls from './SelectControls.js';
import ResetButton from './ResetButton.js';
import ExportButtons from './ExportButtons.js';
import TableProjectOverhead from '../components/TableProjectOverhead';
import { CSVLink } from 'react-csv';
import Grid from '@material-ui/core/Grid';
import { toJSONLocalDate } from '../helpers.js';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { getDateLocale } from '../config.js';
import { withStyles } from '@material-ui/core/styles';
import i18n from 'i18next';
import _ from 'lodash'

const useStyles = theme => ({
	mainGrid: {
		display: 'flex',
		height: '100%',
		width: '100%',
		margin: theme.spacing(0),
		justifyContent: 'space-between',
		'& > *': {
			margin: theme.spacing(0)
		},
	},
	select: {
		minWidth: '100%',
		maxWidth: '100%'
	},
    datePicker: {
        '& .MuiOutlinedInput-root': {
            height: '40px',
            maxWidth: '20ch'
        }
    },
    link: {
        color: "#ffffff",
        "&:hover": {
            color: "#ffffff"
        },
    }
});

/**
 * Reports Overhead
 */
class ReportsOverhead extends Component {
	static contextType = AuthContext;

	state = {}

	constructor(props) {
		super(props);

		// All function bindings to make them accessible
        this.handleChangeSelectProject = this.handleChangeSelectProject.bind( this );
        this.handleChangeSelectTask = this.handleChangeSelectTask.bind( this );
        this.handleChangeSelectDateMin = this.handleChangeSelectDateMin.bind( this );
        this.handleChangeSelectDateMax = this.handleChangeSelectDateMax.bind( this );
        this.handleResetDateButtonClick = this.handleResetDateButtonClick.bind( this );
        this.handleCsvExportClick = this.handleCsvExportClick.bind( this );
        this.getTasksOfProject = this.getTasksOfProject.bind( this );

        // References to call childs
        this.tableProjectOverheadChildRef = React.createRef();
        this.csvLinkRef = React.createRef();

		// Set initial state
		this.state = this.getInitialState();
	}

	/**
	 * STATE HANDLING
	 */

	// Get the initial state variables of this component
	getInitialState() {
		return {
            selectedDate: {
                min: null,
                max: null
            },
            selectedProject: null,
            selectedTask: null,
            selectableProjects: [],
            projectReportDataArray: [],
            projectOverheads: null,
			data: { // Raw loaded data from the backend
                admin: null,
                projects: null,
                tasks: null,
                usersToTasks: null,
                usersToProjects: null,
                users: null,
                userTypes: null
			},
            export: {
                csv: {
                    filename: "",
                    data: []
                }
            }
		}
	}

	// Reset all internal states to initial values and also values propagated to parent
	resetState() {
		this.handleAlertChanged({ severity: "info", message: "" });
		this.handleDataLoadedChanged( false );
        this.handleProgressBarChanged( 0 );
		this.setState( this.getInitialState() );
	}

	/**
	 * LINK TO PARENT COMPONENT FUNCTIONS
	 */

	// Push data loaded changed to parent
	handleDataLoadedChanged( dataLoaded ) {
		if( this.props.dataLoadedChanged ) {
			this.props.dataLoadedChanged( dataLoaded );
		}
	}

    // Push progress bar changed to parent
    handleProgressBarChanged( progress ) {
        if( this.props.progressBarChanged ) {
			this.props.progressBarChanged( progress );
		}
    }

	// Push alert to parent
	handleAlertChanged( alert ) {
		if( this.props.alertChanged ) {
			this.props.alertChanged( alert );
		}
	}

	// Propagate information to parent component
	handleActionInProgressChanged( actionInProgress ) {
		if( this.props.actionInProgressChanged ) {
			this.props.actionInProgressChanged( actionInProgress );
		}
	}

	/**
	 * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
	 */

	// Called by react when this component has been mounted
	async componentDidMount() {
		// Refresh all data from backend
		await this.refreshData();
	}

	// Called by react before this component unmounts
	componentWillUnmount() {
		this.resetState();
	}

	/**
	 * HANDLERS 
	 */

	// Called when the user selects a new project
	async handleChangeSelectProject( projectid ) {
        await this.refreshProjectData( projectid, null, null );
	}

	// Called when the user selects a new task
	handleChangeSelectTask( taskid ) {
		this.setState({ selectedTask: taskid });
	}

    // Called when user clicks the reset date button
    async handleResetDateButtonClick( event ) {
        if( 0 < this.state.projectReportDataArray.length ) {
            await this.refreshProjectData( this.state.selectedProject, null, null );
        }
    }

    // New start date selected
    async handleChangeSelectDateMin( value ) {
        const newDateMin = toJSONLocalDate( value );
        await this.refreshProjectData( this.state.selectedProject, newDateMin, this.state.selectedDate.max );
    }

    // New end date selected
    async handleChangeSelectDateMax( value ) {
        const newDateMax = toJSONLocalDate( value );
        await this.refreshProjectData( this.state.selectedProject, this.state.selectedDate.min, newDateMax );
    }

	/**
	 * LOAD AND PROCESS BACKEND DATA
	 */

    // Gather project data from backend
    // projectid might be the selected projectid, or an empty string (for none) or "all" for all projects
    async refreshProjectData( projectid, searchDateMin, searchDateMax ) {
        this.handleDataLoadedChanged( false );

        this.setState({ projectReportDataArray: [] });

        // Only gather project data if projectid is not an empty string (which is true when selecting "None")
        if( null !== projectid && "" !== projectid ) {

            // Handle case where projectid is "all"
            if( projectid !== "all" ) {

                let projectReportData = null;
                if( null != projectid ) {
                    // Gather project report data            
                    const { statusCode, statusText, resData } = await ReportService.getProjectReport( { projectid: projectid, minDate: searchDateMin, maxDate: searchDateMax } );
                    if( 200 === statusCode && null != resData ) {
                        projectReportData = resData.getProjectReport;
                    } else {
                        // Error, set the alert right away and abort
                        this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                        return;
                    }
                }

                // Use the proper selected date data
                let selectedDateData = {
                    min: projectReportData.resultProject.minDate,
                    max: projectReportData.resultProject.maxDate
                }
                if( null !== projectReportData.searchValues.minDate ) {
                    selectedDateData.min = projectReportData.searchValues.minDate;
                }
                if( null !== projectReportData.searchValues.maxDate ) {
                    selectedDateData.max = projectReportData.searchValues.maxDate;
                }

                // Min date might still be null at this point if there are no accountings on the project
                if( null == selectedDateData.min ) {
                    selectedDateData.min = new Date("01-01-1970")
                }

                let projectReportDataArray = []
                projectReportDataArray.push(projectReportData)

                let projectOverheads = this.generateProjectOverheads(projectReportDataArray)

                this.setState({ selectedDate: selectedDateData, projectReportDataArray: projectReportDataArray, selectedProject: projectid, projectOverheads: projectOverheads });

            } else {
                this.handleProgressBarChanged( 1 )

                let projectReportDataArray = []

                // Progress bar steps
                let progressPerProject = 100.0 / this.state.selectableProjects.length;

                // Request data for all projects
                for( let i = 0; i < this.state.selectableProjects.length; i++ ) {

                    let itProjectId = this.state.selectableProjects[i].id

                    // Gather project report data
                    let projectReportData = null;       
                    const { statusCode, statusText, resData } = await ReportService.getProjectReport( { projectid: itProjectId, minDate: searchDateMin, maxDate: searchDateMax } );
                    if( 200 === statusCode && null != resData ) {
                        projectReportData = resData.getProjectReport;
                    } else {
                        // Error, set the alert right away and abort
                        this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                        return;
                    }

                    projectReportDataArray.push(projectReportData)

                    let progress = (i+1.0)*progressPerProject;
                    this.handleProgressBarChanged( progress )
                }

                // Use the proper selected date data
                let selectedDateData = {
                    min: searchDateMin,
                    max: searchDateMax
                }

                const [ projectsMinDate, projectsMaxDate ] = this.getProjectsDateRange( projectid, projectReportDataArray )
                if( null == selectedDateData.min ) {
                    // We have no min date, use the min date from all the projects
                    selectedDateData.min = projectsMinDate;
                } else {
                    // We already have a min date, check if its earlier than the projects min date. If it is adjust it to the projects min date. If its not, leave it.
                    let selectedDateDataMinObject = new Date( selectedDateData.min );
                    let projectsMinDateObject = new Date( projectsMinDate );

                    if( selectedDateDataMinObject < projectsMinDateObject ) {
                        selectedDateData.min = projectsMinDate;
                    }
                }
                if( null == selectedDateData.max ) {
                    // We have no max date, use the max date from all the projects
                    selectedDateData.max = projectsMaxDate;
                } else {
                    // We already have a max date, check if its later than the projects max date. If it is adjust it to the projects max date. If its not, leave it.
                    let selectedDateDataMaxObject = new Date( selectedDateData.max );
                    let projectsMaxDateObject = new Date( projectsMaxDate );

                    if( selectedDateDataMaxObject > projectsMaxDateObject ) {
                        selectedDateData.max = projectsMaxDate;
                    }
                }

                let projectOverheads = this.generateProjectOverheads(projectReportDataArray)

                this.setState({ selectedDate: selectedDateData, projectReportDataArray: projectReportDataArray, selectedProject: projectid, projectOverheads: projectOverheads })

                this.handleProgressBarChanged( 100 )
            }

        } else {
            this.setState({ selectedDate: null, projectReportDataArray: [], selectedProject: null });
        }

        this.handleDataLoadedChanged( true );
    }

	// Gather all data from the backend
	async refreshData() {
        this.handleDataLoadedChanged( false );

        // Gather project data
        let projectData = null;
        {
            const { statusCode, statusText, resData } = await ProjectService.getProjects( {} );
            if( 200 === statusCode && null != resData ) {
                projectData = resData.getProjects;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
            projectData.sort(function(a, b) { return a.internal_id > b.internal_id ? 1 : -1; });
        }

        // Gather task data
        let taskData = null;
        {
            const { statusCode, statusText, resData } = await TaskService.getTasks( {} );
            if( 200 === statusCode && null != resData ) {
                taskData = resData.getTasks;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }

            // Some tasks do not have the project number in front, fix that if necessary
            for( let taskIndex = 0; taskIndex < taskData.length; taskIndex++ ) {
                let internal_project_id = ""
                for( let projectIndex = 0; projectIndex < projectData.length; projectIndex++ ) {
                    if( projectData[projectIndex].projectid === taskData[taskIndex].projectid ) {
                        internal_project_id = projectData[projectIndex].internal_id
                        break
                    }
                }

                if( internal_project_id !== "" ) {
                    if( taskData[taskIndex].name.startsWith(internal_project_id) === false ) {
                        taskData[taskIndex].name = internal_project_id + " " + taskData[taskIndex].name
                    }
                }
            }
            
            taskData.sort(function(a, b) { return a.name > b.name ? 1 : -1; });
        }

        // Get users to tasks data
        let usersToTasksData = null;
        {
            const { statusCode, statusText, resData } = await UsersToTasksService.getUsersToTasks( {} );
            if( 200 === statusCode && null != resData ) {
                usersToTasksData = resData.getUsersToTasks;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Get users to projects data
        let usersToProjectsData = null;
        {
            const { statusCode, statusText, resData } = await UsersToProjectsService.getUsersToProjects( {} );
            if( 200 === statusCode && null != resData ) {
                usersToProjectsData = resData.getUsersToProjects;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

        // Gather user data
        let userData = null;
        {
            const { statusCode, statusText, resData } = await UserService.getUsers( {} );
            if( 200 === statusCode && null != resData ) {
                userData = resData.getUsers;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
            userData.sort(function(a, b) { return a.lastname > b.lastname ? 1 : -1; });
        }

        // Gather user type data
        let userTypeData = null;
        {
            const { statusCode, statusText, resData } = await UserTypeService.getUserTypes( {} );
            if( 200 === statusCode && null != resData ) {
                userTypeData = resData.getUserTypes;
            } else {
                // Error, set the alert right away and abort
                this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
                return;
            }
        }

		// Gather admin data for the currently logged in user
		let adminData = null;
		{
			const { statusCode, statusText, userAdminData } = await this.context.services.admin.getAdminData( this.context.userid );
			if( 200 === statusCode && null != userAdminData ) {
				adminData = userAdminData;
			} else {
				// Error, set the alert right away and abort
				this.handleAlertChanged({ severity: "error", message: `${statusText}: ${statusCode}` });
				return;
			}
		}

        // Add user type name to all user data entries
        for( let i = 0; i < userData.length; i++ ) {
            let userTypeName = "";
            for( let j = 0; j < userTypeData.length; j++ ) {
                if( userTypeData[j].typeid === userData[i].typeid ) {
                    userTypeName = userTypeData[j].name;
                    break;
                }
            }
            userData[i].userTypeName = userTypeName;
        }

        // Gather list of selectable projects depending on user administraction access or project lead settings
        let selectableProjects = [];
        if( null != adminData ) {
            for( let i = 0; i < projectData.length; i++ ) {

                // As a value entry use {internal_id}-{name} here since this value will show up in the select menu
                let value = `${projectData[i].internal_id} - ${projectData[i].name} (${projectData[i].status})`;

                if( true === adminData.admin.accountings || true === adminData.admin.global ) {

                    // We are admin accounting user or global admin
                    selectableProjects.push( { id: projectData[i].projectid, value: value } );

                } else if( 0 < adminData.projectlead.length ) {

                    // Check if the current user is the project lead
                    for( let j = 0; j < adminData.projectlead.length; j++ ) {

                        if( adminData.projectlead[j] === projectData[i].projectid ) {
                            selectableProjects.push( { id: projectData[i].projectid, value: value } );
                        }
                    }
                }
            }

            // Sort project data
            selectableProjects.sort(function(a, b) { return a.value > b.value ? 1 : -1; });
        }

		this.setState({
            selectableProjects: selectableProjects,
			data: { // Raw loaded data from the backend
                admin: adminData,
                projects: projectData,
                tasks: taskData,
                usersToTasks: usersToTasksData,
                usersToProjects: usersToProjectsData,
                users: userData,
                userTypes: userTypeData
			}
		});

		this.handleDataLoadedChanged( true );
	}

    // Generate project overheads
    generateProjectOverheads( projectReportDataArray ) {

        /*
        * Object with the following structure
        * users = map[userid] with object(firstname, lastname, type)
        * tasks = map[taskid] with object(project_internal_id, project_name, task_name, hours=map[userid])
        */
        let overheads = {
            users: new Map(),
            tasks: new Map()
        }

        for( let i = 0; i < this.state.data.users.length; i++ ) {
            let userType = "";
            for( let j = 0; j < this.state.data.userTypes.length; j++ ) {
                if( this.state.data.userTypes[j].typeid === this.state.data.users[i].typeid ) {
                    userType = this.state.data.userTypes[j].name;
                }
            }

            // Omit (x) Admin, Admin
            if( this.state.data.users[i].firstname.toLowerCase() !== "admin" && this.state.data.users[i].lastname.toLowerCase() !== "admin" ) {
                let userObject = {
                    firstname: this.state.data.users[i].firstname,
                    lastname: this.state.data.users[i].lastname,
                    type: userType
                };
                overheads.users.set(this.state.data.users[i].userid, userObject);
            }
        }

        for( let i = 0; i < projectReportDataArray.length; i++ ) {
            let projectReport = projectReportDataArray[i];
            let projectid = projectReport.searchValues.projectid;
            let project_internal_id = "";
            let project_name = "";

            for( let j = 0; j < this.state.data.projects.length; j++ ) {
                if( this.state.data.projects[j].projectid === projectid ) {
                    project_internal_id = this.state.data.projects[j].internal_id;
                    project_name = this.state.data.projects[j].name;
                    break
                }
            }

            for( let j = 0; j < projectReport.resultUsers.length; j++ ) {
                let resultUser = projectReport.resultUsers[j];
                let userid = resultUser.userid;

                for( let k = 0; k < resultUser.tasks.length; k++ ) {
                    let taskid = resultUser.tasks[k].taskid;
                    let task_name = resultUser.tasks[k].name;
                    let hours_accounted_total = resultUser.tasks[k].hours_accounted_total

                    // Check if task id already exists in map
                    if( true === overheads.tasks.has( taskid ) ) {
                        // Task id does already exist, add the new user entry
                        let taskObject = overheads.tasks.get(taskid)
                        taskObject.hours.set(userid, hours_accounted_total)
                        overheads.tasks.set(taskid, taskObject)

                    } else {
                        // Task id does not yet exist
                        let hours = new Map();
                        hours.set( userid, hours_accounted_total )

                        let taskObject = {
                            project_internal_id: project_internal_id,
                            project_name: project_name,
                            task_name: task_name,
                            hours: hours
                        }

                        overheads.tasks.set(taskid, taskObject);
                    }
                }
            }
        }

        return overheads;
    }

    /**
     * GETTERS
     */

    // Get all tasks for a certain project
    getTasksOfProject( projectid ) {
        let tasks = [];

        if( null != this.state.data.tasks ) {
            for( let i = 0; i < this.state.data.tasks.length; i++ ) {
                if( projectid === "all" ||  this.state.data.tasks[i].projectid === projectid ) {
                    tasks.push( this.state.data.tasks[i] );
                }
            }
        }

        // Sort by task name
        tasks.sort(function(a, b) { 
            if( a.name > b.name ) return 1;
            if( a.name < b.name ) return -1;
            return 0;
        });

        return tasks;
    }

    // Get all users for a certain project
    getUsersOfProject( projectid ) {
        let users = [];

        if( null != this.state.data.usersToProjects ) {
            for( let i = 0; i < this.state.data.usersToProjects.length; i++ ) {
                if( this.state.data.usersToProjects[i].projectid === projectid ) {

                    for( let j = 0; j < this.state.data.users.length; j++ ) {
                        if( this.state.data.users[j].userid === this.state.data.usersToProjects[i].userid ) {
                            users.push( this.state.data.users[j] );

                            if( this.state.data.users[j].typeid != null ) {
                                for( let k = 0; k < this.state.data.userTypes.length; k++ ) {
                                    if( this.state.data.userTypes[k].typeid === this.state.data.users[j].typeid ) {
                                        users[users.length-1].type = this.state.data.userTypes[k].name;
                                    }
                                }
                            } else {
                                users[users.length-1].type = "";
                            }
                        }
                    }
                }
            }
        }

        // Sort user data
        users.sort(function(a, b) { 
            // Sort by user type first, then by user lastname
            if( a.type > b.type ) return 1;
            if( a.type < b.type ) return -1;
            if( a.lastname > b.lastname ) return 1;
            if( a.lastname < b.lastname ) return -1;
            return 0;
        });

        return users;
    }

    /**
     * EXPORT FUNCTIONS
     */
    getCsvEntries(projectOverheads) {
        let entries = [];

        /*
        * Entry style
        * taskid
        * internal_id
        * project_name
        * task_name
        * hours = array of objects(userid, type, lastname, firstname, hours)
        */

        /*
        * projectOverheads is an object with the following structure:
        * users = map[userid] with object(firstname, lastname, type)
        * tasks = map[taskid] with object(project_internal_id, project_name, task_name, hours=map[userid])
        */

        if( null != projectOverheads ) {
            // Prep empty hour list with all usernames
            let hours_template = []
            for( var user of projectOverheads.users.entries() ) {
                let userid = user[0];
                let type = user[1].type;
                let lastname = user[1].lastname;
                let firstname = user[1].firstname;
                let hours = 0
                hours_template.push({ userid: userid, type: type, lastname: lastname, firstname: firstname, hours: hours })
            }

            // Sort hours_template
            hours_template.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.type > b.type ) return 1;
                if( a.type < b.type ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });

            for( var overhead of projectOverheads.tasks.entries() ) {
                let taskid = overhead[0];

                // Check if any task has been selected
                if( null != this.props.selectedTask ) {
                    // A task has been selected, check if we are matching this one, also handle the case where selectedTask is just an empty string
                    if( "" !== this.props.selectedTask ) {
                        if( taskid !== this.props.selectedTask ) {
                            continue;
                        }
                    }
                }

                let internal_id = overhead[1].project_internal_id;
                let project_name = overhead[1].project_name;
                let task_name = overhead[1].task_name;
                let hours = _.cloneDeep( hours_template );

                for( var userHours of overhead[1].hours.entries() ) {
                    let userid = userHours[0];

                    for( let i = 0; i < hours.length; i++ ) {
                        if( hours[i].userid === userid ) {
                            hours[i].hours = userHours[1];
                        }
                    }
                }

                let entry = {
                    taskid: taskid,
                    internal_id: internal_id,
                    project_name: project_name,
                    task_name: task_name,
                    hours: hours
                }

                entries.push( entry );
            }
        }

        // Already do a basic sort of the entries
        return entries.sort( 
            function(a, b) {
                if( a.internal_id === b.internal_id ) {
                    // We are on the same project, sort by task_name
                    return a.task_name > b.task_name ? 1 : -1;
                } else {
                    // We are not on the same project, sort by internal_id
                    return a.internal_id > b.internal_id ? 1 : -1;
                }
            }
        );
    }

    async handleCsvExportClick() {

        // We need the project report to export something
        if( 0 === this.state.projectReportDataArray.length || null == this.state.data.projects ) {
            return;
        }
   
        // We re-generate the project overhead object so we can sort it without affecting the visual representation in the UI
        /*
        * projectOverheads is an object with the following structure:
        * users = map[userid] with object(firstname, lastname, type)
        * tasks = map[taskid] with object(project_internal_id, project_name, task_name, hours=map[userid])
        */
        let projectOverheads = this.generateProjectOverheads(this.state.projectReportDataArray);

        // Generate the entries to put into the CSV, also sorts them properly
        /*
        * Entry style
        * taskid
        * internal_id
        * project_name
        * task_name
        * hours = array of objects(userid, type, lastname, firstname, hours)
        */
        let csvEntries = this.getCsvEntries( projectOverheads )

        // Gather main project details
        let projectName = "All";
        let projectInternalId = "All";
        for( let i = 0; i < this.state.data.projects.length; i++ ) {
            if( this.state.selectedProject === this.state.data.projects[i].projectid ) {
                projectName = this.state.data.projects[i].name;
                projectInternalId = this.state.data.projects[i].internal_id;
                break;
            }
        }

        // Assemble CSV data
        let csvData = [];

        /**
         * Main Header
         * Report Overhead - Summary,<Project Internal ID or All>,<Project Name or All>,<Selected Start Date>,<Selected End Date>
         */
        csvData.push( [ 
            i18n.t("pages.reports.options.overhead.export.summary.headline"), 
            projectInternalId, 
            projectName, 
            this.state.selectedDate.min, 
            this.state.selectedDate.max 
        ] );

        // Assemble CSV file name
        let csvFilename = i18n.t("pages.reports.options.overhead.export.summary.csv.filename", {
            internalid: projectInternalId,
            name: projectName
        });

        /*
              Internal Id,Project Name,Task Name,[one column per user showing the full username]
        */
        let headline = []
        headline.push( i18n.t("pages.reports.options.overhead.export.summary.internal_id") );
        headline.push( i18n.t("pages.reports.options.overhead.export.summary.project_name") );
        headline.push( i18n.t("pages.reports.options.overhead.export.summary.task_name") );

        // We can just grab one line from csv entries for all the usernames in the correct order
        if( 0 < csvEntries.length ) {
            for( let i = 0; i < csvEntries[0].hours.length; i++ ) {

                let full_username = "";
                if( csvEntries[0].hours[i].type !== "" ) {
                    full_username = "(" + csvEntries[0].hours[i].type + ") " + csvEntries[0].hours[i].lastname + " " + csvEntries[0].hours[i].firstname;
                } else {
                    full_username = csvEntries[0].hours[i].lastname + " " + csvEntries[0].hours[i].firstname;
                }
                
                headline.push( full_username );
            }
        }
        csvData.push( headline );

        // Push all the data lines
        for( let i = 0; i < csvEntries.length; i++ ) {
            let hourEntries = []
            for( let j = 0; j < csvEntries[i].hours.length; j++ ) {
                hourEntries.push( csvEntries[i].hours[j].hours )
            }

            csvData.push( [
                csvEntries[i].internal_id,
                csvEntries[i].project_name,
                csvEntries[i].task_name,
                ...hourEntries
            ] )
        }

        // Set the state of the data and automatically click the download link
        this.setState({ 
            export: { 
                csv: {
                    filename: csvFilename,
                    data: csvData
                }
            }},
            () => {
                setTimeout(() => {
                    this.csvLinkRef.current.link.click();
                })
            }
        );
    }

    getProjectsDateRange( projectid, projectReportDataArray) {
        // projectid might be null or "" (for none), "all" (for all), or a specific project id
        let minDate = null;
        let maxDate = null;

        if( projectid !== null ) {
            for( let i = 0; i < projectReportDataArray.length; i++ ) {
                if( projectid === "all" || projectid === projectReportDataArray[i].searchValues.projectid ) {

                    const [ projectMinDate, projectMaxDate ] = this.getProjectDateRange( projectReportDataArray[i] );

                    if( minDate == null ) {
                        if( projectMinDate !== null ) {
                            minDate = projectMinDate;
                        }
                    } else {
                        if( projectMinDate !== null ) {
                            let minDateObject = new Date( minDate );
                            let minProjectDateObject = new Date( projectMinDate );

                            if( minProjectDateObject < minDateObject ) {
                                minDate = projectMinDate;
                            }
                        }
                    }
                    if( maxDate == null ) {
                        if( projectMaxDate !== null ) {
                            maxDate = projectMaxDate;
                        }
                    } else {
                        if( projectMaxDate !== null ) {
                            let maxDateObject = new Date( maxDate );
                            let maxProjectDateObject = new Date( projectMaxDate );

                            if( maxProjectDateObject > maxDateObject ) {
                                maxDate = projectMaxDate;
                            }
                        }
                    }
                }
            }
        }
        return [ minDate, maxDate ]
    }

    getProjectDateRange( projectReport ) {
        let minDate = null;
        let maxDate = null;

        if( null != projectReport ) {
            minDate = projectReport.resultProject.minDate;
            maxDate = projectReport.resultProject.maxDate;
        }
        return [ minDate, maxDate ]
    }

	/**
	 * RENDER FUNCTIONS
	 */

    renderSelectDate( selectedProjectId, projectReportDataArray, selectedDateMin, selectedDateMax ) {
        let locale = null;
        if( null != this.props.language ) {
            locale = getDateLocale( this.props.language );
        }

        const [ projectsMinDate, projectsMaxDate ] = this.getProjectsDateRange( selectedProjectId, projectReportDataArray )

        let minDate = selectedDateMin;
        let maxDate = selectedDateMax;
        if( selectedDateMin == null ) {
            minDate = projectsMinDate;
        }
        if( selectedDateMax == null ) {
            maxDate = projectsMaxDate;
        }

        let dateMin = new Date( minDate );
        let dateMax = new Date( maxDate );
        let dateMinProject = new Date( projectsMinDate );
        let dateMaxProject = new Date( projectsMaxDate );

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <DatePicker
                    autoOk="true"
                    variant="inline"
                    label={i18n.t("pages.reports.select_date_min.label")}
                    helperText={i18n.t("pages.reports.select_date_min.subtext")}
                    inputVariant="outlined"
                    openTo="date"
                    value={dateMin}
                    onChange={this.handleChangeSelectDateMin}
                    minDate={dateMinProject}
                    maxDate={dateMaxProject}
                    format="MMM dd, yyyy"
                    className={this.props.classes.datePicker}
                />
                <DatePicker
                    autoOk="true"
                    variant="inline"
                    label={i18n.t("pages.reports.select_date_max.label")}
                    helperText={i18n.t("pages.reports.select_date_max.subtext")}
                    inputVariant="outlined"
                    openTo="date"
                    value={dateMax}
                    onChange={this.handleChangeSelectDateMax}
                    minDate={dateMinProject}
                    maxDate={dateMaxProject}
                    format="MMM dd, yyyy"
                    className={this.props.classes.datePicker}
                />
            </MuiPickersUtilsProvider>
        )
    }

    // Render overhead details table
    renderOverheadDetails() {

        let selectedTask = this.state.selectedTask;

        return (
            <TableProjectOverhead
                ref={this.tableProjectOverheadChildRef}
                overheads={this.state.projectOverheads}
                selectedTask={selectedTask}
            />
        )
    }

    render() {
		/**
		 * INPUTS
		 * adminData, language, dataLoadedChanged, alertChanged, actionInProgressChanged
		 */

        let selectDate = "";
        let resetDate = "";
        let selectProject = "";
        let selectTask = "";
        let selectedTask = "";
        let renderCsvLink = "";
        let renderExportButtons = "";
        let overheadDetails = "";

        if( null != this.state.selectedTask ) { selectedTask = this.state.selectedTask; }

        let selectableTasks = [];

        if( null != this.state.selectableProjects && this.state.selectableProjects.length > 0 ) {
            let selectedProject = "";
            if( null != this.state.selectedProject ) { selectedProject = this.state.selectedProject; }

            selectProject = (
                <SelectControls
                    selectedEntry={selectedProject}
                    helperText={i18n.t("pages.reports.select_project.subtext")}
                    items={this.state.selectableProjects}
                    onChange={this.handleChangeSelectProject}
                    showButtons={false}
                    showNoneValue={true}
                    showAllValue={true}
                    displayEmpty={true}
                    noneValueName={i18n.t("values.none")}
                    allValueName={i18n.t("values.all")}
                />
            )

            let tasksOfProject = this.getTasksOfProject( this.state.selectedProject );
            if( null == tasksOfProject ) {
                tasksOfProject = [];
            }

            for( let i = 0; i < tasksOfProject.length; i++ ) {
                let value = `${tasksOfProject[i].name} (${tasksOfProject[i].status})`;
                selectableTasks.push( { id: tasksOfProject[i].taskid, value: value } );
            }
        }

        if( 0 < this.state.projectReportDataArray.length ) {
            selectDate = this.renderSelectDate( this.state.selectedProject, this.state.projectReportDataArray, this.state.selectedDate.min, this.state.selectedDate.max );
            resetDate = (
                <ResetButton 
                    title={i18n.t("pages.reports.options.project.reset_date.tooltip")}
                    onClick={(event) => this.handleResetDateButtonClick(event)}
                    disabled={false}
                />
            )

            selectTask = (
                <SelectControls
                    selectedEntry={selectedTask}
                    helperText={i18n.t("pages.reports.select_task.subtext")}
                    items={selectableTasks}
                    onChange={this.handleChangeSelectTask}
                    showButtons={false}
                    showNoneValue={true}
                    displayEmpty={true}
                    noneValueName={i18n.t("values.all")}
                />
            )
            
            renderCsvLink = (
                <CSVLink
                    ref={this.csvLinkRef}
                    data={this.state.export.csv.data}
                    filename={this.state.export.csv.filename}
                    className="hidden"
                    target="_blank"
                />
            )

            // Export CSV button, tooltip changes depending on selected option
            let exportCsvButtons = [];

            exportCsvButtons.push({
                showIcon: true,
                showText: false,
                tooltip: i18n.t("pages.reports.overhead_reports.buttons.csv_summary_tooltip"),
                customData: "summary"
            })

            renderExportButtons = (
                <ExportButtons 
                    csvButtons={exportCsvButtons}
                    onCsvClick={(customData) => this.handleCsvExportClick()}
                />
            )

            overheadDetails = this.renderOverheadDetails();
        }

        return (
			<div>
				<Grid container className={this.props.classes.mainGrid} justify="flex-start" alignItems="flex-start" spacing={1}>
					<Grid item container xs={12} className={this.props.classes.select} spacing={1} wrap='nowrap'>
                        <Grid item container xs={10} spacing={1}>
                            <Grid item>
                                {selectProject}
                            </Grid>
                            <Grid item>
                                {selectDate}
                            </Grid>
                            <Grid item>
                                {resetDate}
                            </Grid>
                            <Grid item>
                                {selectTask}
                            </Grid>
                        </Grid>
                        <Grid item container xs={2} justify="flex-end">
                            {renderCsvLink}
                            {renderExportButtons}
                        </Grid>
					</Grid>
                    {overheadDetails}
				</Grid>
			</div>
        );
    }
};

export default withStyles(useStyles)(ReportsOverhead);