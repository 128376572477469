import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SearchControls from '../components/SearchControls';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { StyledTableCell, StyledTableRow, StyledTableHead, StyledTable, StyledTableSortLabel, stableSort, getComparator } from '../components/StyledTable.js';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import _ from 'lodash'

const useStyles = theme => ({
    tablePaginationGrid: {
        height: '60px',
        '& > *': {
            margin: theme.spacing(0)
        },
    },
    tablePagination: {
        '& .MuiTablePagination-toolbar': {
            height: '60px',
            minHeight: '60px',
        },
    },
    tableCell: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.0),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5)
    },
});

/**
 * Table Project Overhead
 */
class TableProjectOverhead extends Component {
    state = {}

    constructor(props) {
        super(props);

        // All function bindings to make them accessible
        this.handleChangeSearchField = this.handleChangeSearchField.bind( this );
        this.handleRequestSort = this.handleRequestSort.bind( this );

        // Set initial state
        this.state = this.getInitialState();
    }

    /**
     * STATE HANDLING
     */

    // Get the initial state variables of this component
    getInitialState() {
        return {
            searchFieldEntry: "",
            order: "asc",
            orderBy: 0
        }
    }

    // Reset all internal states to initial values and also values propagated to parent
    resetState() {
        this.setState( this.getInitialState() );
    }

    /**
     * REACT CALLS WHILE COMPONENT MOUNTS AND UNMOUNTS
     */

    // Called by react before this component unmounts
    componentWillUnmount() {
        this.resetState();
    }

    /**
     * CALLBACK FUNCTIONS FOR SEARCH FIELD
     */

    // Called when text is entered in the search field
    handleChangeSearchField(value) {
        this.setState({ searchFieldEntry: value });
    }

    /**
     * DATA SORT
     */

    // Handle table sort
    handleRequestSort( event, property ) {

        // Check if clicked on same property
        if( this.state.orderBy === property ) {

            // User clicked on same property
            // If already sorted in ascending order, switch to descending order
            if( this.state.order === 'asc' ) {
                this.setState({ order: 'desc', orderBy: property });
            } else {

                // If already sorted in descending order, switch to no order
                this.setState({ order: 'asc', orderBy: 0 });
            }

        } else {
            // User clicked on new property, sort new property in ascending order
            this.setState({ order: 'asc', orderBy: property });
        }
    };

    // Sort the given table data
    sortTableData( entries ) {
        // Handle case where no ordering is selected
        if( this.state.orderBy === 0 ) {
            return entries.sort( 
                function(a, b) {
                    if( a.internal_id === b.internal_id ) {
                        // We are on the same project, sort by task_name
                        return a.task_name > b.task_name ? 1 : -1;
                    } else {
                        // We are not on the same project, sort by internal_id
                        return a.internal_id > b.internal_id ? 1 : -1;
                    }
                }
            );
        }

        // Check if we are sorting by a userid hour column, then we need to do something more special
        // since the stableSort cannot handle such an array of objects in its sort
        if( this.state.orderBy !== "internal_id" && this.state.orderBy !== "project_name" && this.state.orderBy !== "task_name" ) {
            let orderBy = this.state.orderBy;
            let order = this.state.order;

            return entries.sort(
                function(a, b) {
                    let hours_a = 0
                    for( let i = 0; i < a.hours.length; i++ ) {
                        if( a.hours[i].userid === orderBy ) {
                            hours_a = a.hours[i].hours;
                            break;
                        }
                    }

                    let hours_b = 0
                    for( let i = 0; i < b.hours.length; i++ ) {
                        if( b.hours[i].userid === orderBy ) {
                            hours_b = b.hours[i].hours;
                            break;
                        }
                    }

                    if( order === 'desc' ) {
                        return hours_a > hours_b ? 1 : -1
                    } else {
                        return hours_a < hours_b ? 1 : -1
                    }
                }
            )

        } else {
            // We can use the regular stableSort
            return stableSort( entries, getComparator(this.state.order, this.state.orderBy) );
        }
    }

    /**
     * GETTERS
     */

    // Get the table entries depending on current selections by the user
    getTableEntries() {
        let entries = [];

        /*
        * Entry style
        * taskid
        * internal_id
        * project_name
        * task_name
        * hours = array of objects(userid, type, lastname, firstname, hours)
        */

        /*
        * this.props.overheads is an object with the following structure:
        * users = map[userid] with object(firstname, lastname, type)
        * tasks = map[taskid] with object(project_internal_id, project_name, task_name, hours=map[userid])
        */

        if( null != this.props.overheads ) {
            // Prep empty hour list with all usernames
            let hours_template = []
            for( var user of this.props.overheads.users.entries() ) {
                let userid = user[0];
                let type = user[1].type;
                let lastname = user[1].lastname;
                let firstname = user[1].firstname;
                let hours = 0
                hours_template.push({ userid: userid, type: type, lastname: lastname, firstname: firstname, hours: hours })
            }

            // Sort hours_template
            hours_template.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.type > b.type ) return 1;
                if( a.type < b.type ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });

            for( var overhead of this.props.overheads.tasks.entries() ) {
                let taskid = overhead[0];

                // Check if any task has been selected
                if( null != this.props.selectedTask ) {
                    // A task has been selected, check if we are matching this one, also handle the case where selectedTask is just an empty string
                    if( "" !== this.props.selectedTask ) {
                        if( taskid !== this.props.selectedTask ) {
                            continue;
                        }
                    }
                }

                let internal_id = overhead[1].project_internal_id;
                let project_name = overhead[1].project_name;
                let task_name = overhead[1].task_name;
                let hours = _.cloneDeep( hours_template );

                for( var userHours of overhead[1].hours.entries() ) {
                    let userid = userHours[0];

                    for( let i = 0; i < hours.length; i++ ) {
                        if( hours[i].userid === userid ) {
                            hours[i].hours = userHours[1];
                        }
                    }
                }

                let entry = {
                    taskid: taskid,
                    internal_id: internal_id,
                    project_name: project_name,
                    task_name: task_name,
                    hours: hours
                }

                entries.push( entry );
            }
        }

        // Already do a basic sort of the entries
        return entries.sort( 
            function(a, b) {
                if( a.internal_id === b.internal_id ) {
                    // We are on the same project, sort by task_name
                    return a.task_name > b.task_name ? 1 : -1;
                } else {
                    // We are not on the same project, sort by internal_id
                    return a.internal_id > b.internal_id ? 1 : -1;
                }
            }
        );
    }

    /**
     * RENDERERS
     */

    // Render overheads table
    renderOverheadsTable() {
        
        /*
        * this.props.overheads is an object with the following structure:
        * users = map[userid] with object(firstname, lastname, type)
        * tasks = map[taskid] with object(project_internal_id, project_name, task_name, hours=map[userid])
        */

        // Assemble table content
        const tableHeader = [
            { key: "internal_id", span: 1, align: "left", sortable: true, style: { paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.reports.overhead_reports.overheads.table.header.internal_id") },
            { key: "project_name", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.reports.overhead_reports.overheads.table.header.project_name") },
            { key: "task_name", span: 1, align: "left", sortable: true, style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" }, text: i18n.t("pages.reports.overhead_reports.overheads.table.header.task_name") }
        ];

        // Add additional headers for each user
        if( this.props.overheads ) {
            let userHeaders = []
            for( var user of this.props.overheads.users.entries() ) {
                let userid = user[0];
                let firstname = user[1].firstname;
                let lastname = user[1].lastname;
                let type = user[1].type;

                let full_username = ""
                if( type !== "" ) {
                    full_username = "(" + type + ") " + lastname + ", " + firstname;
                } else {
                    full_username = lastname + ", " + firstname;
                }

                let tableHeaderObject = {
                    key: userid,
                    span: 1,
                    align: "left",
                    sortable: true,
                    style: { borderStyle: "solid", borderLeftWidth: 1, paddingLeft: "25px", paddingRight: "5px" },
                    firstname: firstname,
                    lastname: lastname,
                    type: type,
                    text: full_username
                }
                userHeaders.push(tableHeaderObject)
            }

            // Sort user headers
            userHeaders.sort(function(a, b) { 
                // Sort by user type first, then by user lastname
                if( a.type > b.type ) return 1;
                if( a.type < b.type ) return -1;
                if( a.lastname > b.lastname ) return 1;
                if( a.lastname < b.lastname ) return -1;
                return 0;
            });

            tableHeader.push(...userHeaders);
        }

        let tableEntries = this.getTableEntries();

        // Filter table data if any search entry has been given
        if( this.state.searchFieldEntry !== "" ) {
            tableEntries = tableEntries.filter( entry => ( 
                String(entry.internal_id.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()) || 
                String(entry.project_name.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase()) || 
                String(entry.task_name.toLowerCase()).includes(this.state.searchFieldEntry.toLowerCase())
            ));
        }

        // Sort the entries depending on selection
        const tableEntriesSorted = this.sortTableData( tableEntries );

        return (
            <div>
                <TableContainer component={Paper} style={{ minHeight: "475px", height: "475px", maxHeight: "475px", overflowY: "auto" }}>
                    <StyledTable stickyHeader>
                        <StyledTableHead>
                            <StyledTableRow key={"project-overhead-header"}>
                                {tableHeader.map((col) => (
                                    <StyledTableCell 
                                        key={col.key} 
                                        colSpan={col.span} 
                                        align={col.align} 
                                        style={col.style}
                                    >
                                        <StyledTableSortLabel
                                            active={this.state.orderBy === col.key}
                                            hideSortIcon={false}
                                            direction={this.state.orderBy === col.key ? this.state.order : 'asc'}
                                            onClick={(event) => this.handleRequestSort(event, col.key)}
                                            disabled={!(col.sortable)}
                                        >
                                            {col.text}
                                        </StyledTableSortLabel>
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                            {tableEntriesSorted.map((entry) => {
                                return (
                                    <StyledTableRow key={entry.taskid}>
                                        <StyledTableCell className={this.props.classes.tableCell} key={entry.taskid.concat("internal_id")} width="5%">
                                            {entry.internal_id}
                                        </StyledTableCell>
                                        <StyledTableCell className={this.props.classes.tableCell} key={entry.taskid.concat("project_name")} width="10%">
                                            {entry.project_name}
                                        </StyledTableCell>
                                        <StyledTableCell className={this.props.classes.tableCell} key={entry.taskid.concat("task_name")} width="20%">
                                            {entry.task_name}
                                        </StyledTableCell>
                                        {entry.hours.map((col) => (
                                            <StyledTableCell className={this.props.classes.tableCell} key={entry.taskid.concat(col.userid)} width="10%">
                                                {col.hours}
                                            </StyledTableCell>
                                        ))}
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
            </div>
        )
    }

    // Takes props: language, ref, overheads, selectedTask
    render() {
        let overheadsTable = null;
        let searchControls = null;

        if( null != this.props.overheads ) {
            overheadsTable = this.renderOverheadsTable();
            searchControls = (
                <SearchControls
                    label={i18n.t("pages.reports.overhead_reports.search.label")}
                    helperText={i18n.t("pages.reports.overhead_reports.search.subtext")}
                    showAddButton={false}
                    showRefreshButton={false}
                    onChange={this.handleChangeSearchField} 
                />
            )
        }

        return (
            <Grid item container justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12}>
                    {searchControls}
                </Grid>
                <Grid item xs={12}>
                    {overheadsTable}
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(useStyles)(TableProjectOverhead);